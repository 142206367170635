import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)/(platform)": [26,[2,4]],
		"/(app)/(platform)/about": [35,[2,4]],
		"/(app)/admin": [~68,[2,10]],
		"/(app)/admin/benefits": [~69,[2,10]],
		"/(app)/admin/courses": [70,[2,10]],
		"/(app)/admin/directory": [~71,[2,10,11]],
		"/(app)/admin/directory/categories": [~72,[2,10,11]],
		"/(app)/admin/directory/tags": [~73,[2,10,11]],
		"/(app)/admin/faqs": [~74,[2,10,12]],
		"/(app)/admin/faqs/categories": [~75,[2,10,12]],
		"/(app)/admin/jobs": [76,[2,10]],
		"/(app)/admin/livestreams": [~77,[2,10]],
		"/(app)/admin/memberships": [~78,[2,10,13]],
		"/(app)/admin/memberships/features": [~79,[2,10,13]],
		"/(app)/admin/news": [~80,[2,10,14]],
		"/(app)/admin/news/categories": [~81,[2,10,14]],
		"/(app)/admin/playlists": [~82,[2,10,15]],
		"/(app)/admin/playlists/categories": [~84,[2,10,15]],
		"/(app)/admin/playlists/[playlistId]": [~83,[2,10,15]],
		"/(app)/admin/products": [~85,[2,10,16]],
		"/(app)/admin/products/categories": [~86,[2,10,16]],
		"/(app)/admin/profile": [~87,[2,10]],
		"/(app)/admin/projects": [~88,[2,10]],
		"/(app)/admin/team": [~89,[2,10,17]],
		"/(app)/admin/team/endorsements": [~90,[2,10,17]],
		"/(app)/admin/testimonials": [~91,[2,10]],
		"/(app)/admin/tickets": [~92,[2,10,18]],
		"/(app)/admin/tickets/categories": [~93,[2,10,18]],
		"/(app)/admin/tools": [~94,[2,10,19]],
		"/(app)/admin/tools/categories": [~95,[2,10,19]],
		"/(app)/admin/tools/subcategories": [~96,[2,10,19]],
		"/(app)/admin/users": [~97,[2,10]],
		"/(app)/admin/videos": [~98,[2,10,20]],
		"/(app)/admin/videos/categories": [~99,[2,10,20]],
		"/(app)/admin/videos/materials": [~100,[2,10,20]],
		"/(app)/admin/videos/sectors": [~101,[2,10,20]],
		"/(app)/admin/videos/tags": [~102,[2,10,20]],
		"/(app)/admin/website": [~103,[2,10,21]],
		"/(app)/admin/website/homepage": [~104,[2,10,21]],
		"/(app)/admin/website/pages": [~105,[2,10,21]],
		"/(app)/(platform)/agreement": [36,[2,4]],
		"/(app)/(platform)/consultations": [37,[2,4]],
		"/(app)/(platform)/directory": [38,[2,4]],
		"/(app)/(platform)/downloads": [39,[2,4]],
		"/(app)/(platform)/error": [40,[2,4]],
		"/(app)/(authentication)/(signup)/forgot": [22,[2,3]],
		"/(app)/(platform)/knowledgebase": [41,[2,4]],
		"/(app)/(platform)/live": [42,[2,4]],
		"/(app)/(authentication)/(signup)/login": [23,[2,3]],
		"/(app)/(platform)/membership": [43,[2,4]],
		"/(app)/(platform)/mission": [44,[2,4]],
		"/(app)/(platform)/news": [45,[2,4]],
		"/(app)/(platform)/news/[newsSlug]": [46,[2,4]],
		"/(app)/(platform)/(videos)/playlists": [27,[2,4,5,6]],
		"/(app)/(platform)/pricing": [~47,[2,4]],
		"/(app)/(platform)/privacy": [48,[2,4]],
		"/(app)/(platform)/profile": [~49,[2,4,9]],
		"/(app)/(platform)/profile/account/discounts": [50,[2,4,9]],
		"/(app)/(platform)/profile/account/projects": [~51,[2,4,9]],
		"/(app)/(platform)/profile/account/settings": [~52,[2,4,9]],
		"/(app)/(platform)/profile/account/subscription": [~53,[2,4,9]],
		"/(app)/(platform)/profile/support/tickets": [~54,[2,4,9]],
		"/(app)/(platform)/profile/twi/library": [~55,[2,4,9]],
		"/(app)/(platform)/profile/welcome": [~56,[2,4,9]],
		"/(app)/(platform)/public/profile/[profileSlug]": [~57,[2,4]],
		"/(app)/(authentication)/(signup)/register": [24,[2,3]],
		"/(app)/(authentication)/(signup)/reset": [~25,[2,3]],
		"/(app)/(platform)/success": [58,[2,4]],
		"/(app)/(platform)/support": [59,[2,4]],
		"/(app)/(platform)/support/faqs": [60,[2,4]],
		"/(app)/(platform)/support/ticket": [~61,[2,4]],
		"/(app)/(platform)/team": [62,[2,4]],
		"/(app)/(platform)/testimonials": [63,[2,4]],
		"/(app)/(platform)/tools": [64,[2,4]],
		"/(app)/(platform)/tools/[subcategorySlug]": [65,[2,4]],
		"/(app)/(platform)/trainers": [66,[2,4]],
		"/(app)/(platform)/(videos)/videos": [28,[2,4,5,7]],
		"/(app)/(platform)/(videos)/videos/free": [29,[2,4,5,7]],
		"/(app)/(platform)/(videos)/videos/latest": [30,[2,4,5,7]],
		"/(app)/(platform)/(videos)/videos/promotional": [31,[2,4,5,7]],
		"/(app)/(platform)/(videos)/videos/sector/[sectorSlug]": [32,[2,4,5,7]],
		"/(app)/(platform)/video/[slug]": [~67,[2,4]],
		"/(app)/(platform)/(videos)/zzz_playlists": [33,[2,4,5,8]],
		"/(app)/(platform)/(videos)/zzz_playlists/sector/[sectorSlug]": [34,[2,4,5,8]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';